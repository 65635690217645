import * as API from "@/services/API";

export default {
  getAchartModuleResult(Id) {
    return API.apiClient.get(`/alightmoduleresult/${Id}`);
  },
  getBchartModuleResult(Id) {
    return API.apiClient.get(`/blightmoduleresult/${Id}`);
  },
  getCchartModuleResult(Id) {
    return API.apiClient.get(`/clightmoduleresult/${Id}`);
  },
  getDchartModuleResult(Id) {
    return API.apiClient.get(`/dlightmoduleresult/${Id}`);
  },
  getEchartModuleResult(Id) {
    return API.apiClient.get(`/elightmoduleresult/${Id}`);
  },
  getFchartModuleResult(Id) {
    return API.apiClient.get(`/flightmoduleresult/${Id}`);
  },
};
