<template>
  <div>
    <v-app>
      <v-main>
        <router-view />
      </v-main>
    </v-app>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/navigation/Footer";
export default {
  name: "App",
  components: { Footer },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>
<style></style>
