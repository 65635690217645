import auth from "@/middleware/auth";
import admin from "@/middleware/admin";
import famchart from "@/middleware/famchart";
// eslint-disable-next-line no-unused-vars
import pcm from "@/middleware/pcm";
// eslint-disable-next-line no-unused-vars
import guest from "@/middleware/guest";

const amainIndex = () =>
  import(/* webpackChunkName: "amainI" */ "../views/FsMain/amain/index");
const bmainIndex = () =>
  import(/* webpackChunkName: "bmainI" */ "../views/FsMain/bmain/index");
const cmainIndex = () =>
  import(/* webpackChunkName: "cmainI" */ "../views/FsMain/cmain/index");
const dmainIndex = () =>
  import(/* webpackChunkName: "dmainI" */ "../views/FsMain/dmain/index");
const emainIndex = () =>
  import(/* webpackChunkName: "emainI" */ "../views/FsMain/emain/index");
const fmainIndex = () =>
  import(/* webpackChunkName: "fmainI" */ "../views/FsMain/fmain/index");
const gmainIndex = () =>
  import(/* webpackChunkName: "gmainI" */ "../views/FsMain/gmain/index");
const hmainIndex = () =>
  import(/* webpackChunkName: "hmainI" */ "../views/FsMain/hmain/index");
const imainIndex = () =>
  import(/* webpackChunkName: "imainI" */ "../views/FsMain/imain/index");
const jmainIndex = () =>
  import(/* webpackChunkName: "jmainI" */ "../views/FsMain/jmain/index");
const kmainIndex = () =>
  import(/* webpackChunkName: "kmainI" */ "../views/FsMain/kmain/index");
const lmainIndex = () =>
  import(/* webpackChunkName: "lmainI" */ "../views/FsMain/lmain/index");
const mmainIndex = () =>
  import(/* webpackChunkName: "mmainI" */ "../views/FsMain/mmain/index");
const nmainIndex = () =>
  import(/* webpackChunkName: "nmainI" */ "../views/FsMain/nmain/index");
const omainIndex = () =>
  import(/* webpackChunkName: "omainI" */ "../views/FsMain/omain/index");
const amainCreate = () =>
  import(/* webpackChunkName: "amainC" */ "../views/FsMain/amain/create");
const bmainCreate = () =>
  import(/* webpackChunkName: "bmainC" */ "../views/FsMain/bmain/create");
const cmainCreate = () =>
  import(/* webpackChunkName: "cmainC" */ "../views/FsMain/cmain/create");
const dmainCreate = () =>
  import(/* webpackChunkName: "dmainC" */ "../views/FsMain/dmain/create");
const emainCreate = () =>
  import(/* webpackChunkName: "emainC" */ "../views/FsMain/emain/create");
const fmainCreate = () =>
  import(/* webpackChunkName: "fmainC" */ "../views/FsMain/fmain/create");
const gmainCreate = () =>
  import(/* webpackChunkName: "gmainC" */ "../views/FsMain/gmain/create");
const hmainCreate = () =>
  import(/* webpackChunkName: "hmainC" */ "../views/FsMain/hmain/create");
const imainCreate = () =>
  import(/* webpackChunkName: "imainC" */ "../views/FsMain/imain/create");
const jmainCreate = () =>
  import(/* webpackChunkName: "jmainC" */ "../views/FsMain/jmain/create");
const kmainCreate = () =>
  import(/* webpackChunkName: "kmainC" */ "../views/FsMain/kmain/create");
const lmainCreate = () =>
  import(/* webpackChunkName: "lmainC" */ "../views/FsMain/lmain/create");
const mmainCreate = () =>
  import(/* webpackChunkName: "mmainC" */ "../views/FsMain/mmain/create");
const nmainCreate = () =>
  import(/* webpackChunkName: "nmainC" */ "../views/FsMain/nmain/create");
const omainCreate = () =>
  import(/* webpackChunkName: "omainC" */ "../views/FsMain/omain/create");
const amainView = () =>
  import(/* webpackChunkName: "amainV" */ "../views/FsMain/amain/view");
const bmainView = () =>
  import(/* webpackChunkName: "bmainV" */ "../views/FsMain/bmain/view");
const cmainView = () =>
  import(/* webpackChunkName: "cmainV" */ "../views/FsMain/cmain/view");
const dmainView = () =>
  import(/* webpackChunkName: "dmainV" */ "../views/FsMain/dmain/view");
const emainView = () =>
  import(/* webpackChunkName: "emainV" */ "../views/FsMain/emain/view");
const fmainView = () =>
  import(/* webpackChunkName: "fmainV" */ "../views/FsMain/fmain/view");
const gmainView = () =>
  import(/* webpackChunkName: "gmainV" */ "../views/FsMain/gmain/view");
const hmainView = () =>
  import(/* webpackChunkName: "hmainV" */ "../views/FsMain/hmain/view");
const imainView = () =>
  import(/* webpackChunkName: "imainV" */ "../views/FsMain/imain/view");
const jmainView = () =>
  import(/* webpackChunkName: "jmainV" */ "../views/FsMain/jmain/view");
const kmainView = () =>
  import(/* webpackChunkName: "kmainV" */ "../views/FsMain/kmain/view");
const lmainView = () =>
  import(/* webpackChunkName: "lmainV" */ "../views/FsMain/lmain/view");
const mmainView = () =>
  import(/* webpackChunkName: "mmainV" */ "../views/FsMain/mmain/view");
const nmainView = () =>
  import(/* webpackChunkName: "nmainV" */ "../views/FsMain/nmain/view");
const omainView = () =>
  import(/* webpackChunkName: "omainV" */ "../views/FsMain/omain/view");
const amainEdit = () =>
  import(/* webpackChunkName: "amainE" */ "../views/FsMain/amain/edit");
const bmainEdit = () =>
  import(/* webpackChunkName: "bmainE" */ "../views/FsMain/bmain/edit");
const cmainEdit = () =>
  import(/* webpackChunkName: "cmainE" */ "../views/FsMain/cmain/edit");
const dmainEdit = () =>
  import(/* webpackChunkName: "dmainE" */ "../views/FsMain/dmain/edit");
const emainEdit = () =>
  import(/* webpackChunkName: "emainE" */ "../views/FsMain/emain/edit");
const fmainEdit = () =>
  import(/* webpackChunkName: "fmainE" */ "../views/FsMain/fmain/edit");
const gmainEdit = () =>
  import(/* webpackChunkName: "gmainE" */ "../views/FsMain/gmain/edit");
const hmainEdit = () =>
  import(/* webpackChunkName: "hmainE" */ "../views/FsMain/hmain/edit");
const imainEdit = () =>
  import(/* webpackChunkName: "imainE" */ "../views/FsMain/imain/edit");
const jmainEdit = () =>
  import(/* webpackChunkName: "jmainE" */ "../views/FsMain/jmain/edit");
const kmainEdit = () =>
  import(/* webpackChunkName: "kmainE" */ "../views/FsMain/kmain/edit");
const lmainEdit = () =>
  import(/* webpackChunkName: "lmainE" */ "../views/FsMain/lmain/edit");
const mmainEdit = () =>
  import(/* webpackChunkName: "mmainE" */ "../views/FsMain/mmain/edit");
const nmainEdit = () =>
  import(/* webpackChunkName: "nmainE" */ "../views/FsMain/nmain/edit");
const omainEdit = () =>
  import(/* webpackChunkName: "omainE" */ "../views/FsMain/omain/edit");
const fchartIndex = () =>
  import(/* webpackChunkName: "fchartI" */ "../views/Fchart/index");
const fchartCreate = () =>
  import(/* webpackChunkName: "fchartC" */ "../views/Fchart/create");
const fchartView = () =>
  import(/* webpackChunkName: "fchartV" */ "../views/Fchart/view");
const fchartEdit = () =>
  import(/* webpackChunkName: "fchartE" */ "../views/Fchart/edit");
const fchartMemberIndex = () =>
  import(
    /* webpackChunkName: "fchartmemberI" */ "../views/Fchart/member/index"
  );
const fchartMemberCreate = () =>
  import(
    /* webpackChunkName: "fchartmemberC" */ "../views/Fchart/member/create"
  );
const fchartMemberView = () =>
  import(/* webpackChunkName: "fchartmemberV" */ "../views/Fchart/member/view");
const fchartMemberEdit = () =>
  import(/* webpackChunkName: "fchartmemberE" */ "../views/Fchart/member/edit");
const fchartStatus = () =>
  import(
    /* webpackChunkName: "fchartstatus" */ "../views/Fchart/status/status"
  );
const fchartDef = () =>
  import(/* webpackChunkName: "fchartdef" */ "../views/Fchart/def/index");
const fchartAdef = () =>
  import(/* webpackChunkName: "fchartadef" */ "../views/Fchart/def/adef");
const fchartBdef = () =>
  import(/* webpackChunkName: "fchartbdef" */ "../views/Fchart/def/bdef");
const fchartCdef = () =>
  import(/* webpackChunkName: "fchartcdef" */ "../views/Fchart/def/cdef");
const fchartDdef = () =>
  import(/* webpackChunkName: "fchartddef" */ "../views/Fchart/def/ddef");
const fchartEdef = () =>
  import(/* webpackChunkName: "fchartedef" */ "../views/Fchart/def/edef");
const fchartFdef = () =>
  import(/* webpackChunkName: "fchartfdef" */ "../views/Fchart/def/fdef");
const fchartGdef = () =>
  import(/* webpackChunkName: "fchartgdef" */ "../views/Fchart/def/gdef");
const fchartHdef = () =>
  import(/* webpackChunkName: "fcharthdef" */ "../views/Fchart/def/hdef");
const fchartIdef = () =>
  import(/* webpackChunkName: "fchartidef" */ "../views/Fchart/def/idef");
const fchartJdef = () =>
  import(/* webpackChunkName: "fchartjdef" */ "../views/Fchart/def/jdef");
const fchartKdef = () =>
  import(/* webpackChunkName: "fchartkdef" */ "../views/Fchart/def/kdef");
const fchartLdef = () =>
  import(/* webpackChunkName: "fchartldef" */ "../views/Fchart/def/ldef");
const fchartMdef = () =>
  import(/* webpackChunkName: "fchartmdef" */ "../views/Fchart/def/mdef");
const fchartNdef = () =>
  import(/* webpackChunkName: "fchartndef" */ "../views/Fchart/def/ndef");
const fchartOdef = () =>
  import(/* webpackChunkName: "fchartodef" */ "../views/Fchart/def/odef");

const fchartInvitation = () =>
  import(
    /* webpackChunkName: "fchartsinvitation" */ "../views/Fchart/status/invitation"
  );
const routes = [
  {
    path: "/amain",
    name: "amainIndex",
    meta: { middleware: [auth, admin] },
    component: amainIndex,
  },
  {
    path: "/bmain",
    name: "bmainIndex",
    meta: { middleware: [auth, admin] },
    component: bmainIndex,
  },
  {
    path: "/cmain",
    name: "cmainIndex",
    meta: { middleware: [auth, admin] },
    component: cmainIndex,
  },
  {
    path: "/dmain",
    name: "dmainIndex",
    meta: { middleware: [auth, admin] },
    component: dmainIndex,
  },
  {
    path: "/emain",
    name: "emainIndex",
    meta: { middleware: [auth, admin] },
    component: emainIndex,
  },
  {
    path: "/fmain",
    name: "fmainIndex",
    meta: { middleware: [auth, admin] },
    component: fmainIndex,
  },
  {
    path: "/gmain",
    name: "gmainIndex",
    meta: { middleware: [auth, admin] },
    component: gmainIndex,
  },
  {
    path: "/hmain",
    name: "hmainIndex",
    meta: { middleware: [auth, admin] },
    component: hmainIndex,
  },
  {
    path: "/imain",
    name: "imainIndex",
    meta: { middleware: [auth, admin] },
    component: imainIndex,
  },
  {
    path: "/jmain",
    name: "jmainIndex",
    meta: { middleware: [auth, admin] },
    component: jmainIndex,
  },
  {
    path: "/kmain",
    name: "kmainIndex",
    meta: { middleware: [auth, admin] },
    component: kmainIndex,
  },
  {
    path: "/lmain",
    name: "lmainIndex",
    meta: { middleware: [auth, admin] },
    component: lmainIndex,
  },
  {
    path: "/mmain",
    name: "mmainIndex",
    meta: { middleware: [auth, admin] },
    component: mmainIndex,
  },
  {
    path: "/nmain",
    name: "nmainIndex",
    meta: { middleware: [auth, admin] },
    component: nmainIndex,
  },
  {
    path: "/omain",
    name: "omainIndex",
    meta: { middleware: [auth, admin] },
    component: omainIndex,
  },
  {
    path: "/amaincreate",
    name: "amainCreate",
    meta: { middleware: [auth, admin] },
    component: amainCreate,
  },
  {
    path: "/bmaincreate",
    name: "bmainCreate",
    meta: { middleware: [auth, admin] },
    component: bmainCreate,
  },
  {
    path: "/cmaincreate",
    name: "cmainCreate",
    meta: { middleware: [auth, admin] },
    component: cmainCreate,
  },
  {
    path: "/dmaincreate",
    name: "dmainCreate",
    meta: { middleware: [auth, admin] },
    component: dmainCreate,
  },
  {
    path: "/emaincreate",
    name: "emainCreate",
    meta: { middleware: [auth, admin] },
    component: emainCreate,
  },
  {
    path: "/fmaincreate",
    name: "fmainCreate",
    meta: { middleware: [auth, admin] },
    component: fmainCreate,
  },
  {
    path: "/gmaincreate",
    name: "gmainCreate",
    meta: { middleware: [auth, admin] },
    component: gmainCreate,
  },
  {
    path: "/hmaincreate",
    name: "hmainCreate",
    meta: { middleware: [auth, admin] },
    component: hmainCreate,
  },
  {
    path: "/imaincreate",
    name: "imainCreate",
    meta: { middleware: [auth, admin] },
    component: imainCreate,
  },
  {
    path: "/jmaincreate",
    name: "jmainCreate",
    meta: { middleware: [auth, admin] },
    component: jmainCreate,
  },
  {
    path: "/kmaincreate",
    name: "kmainCreate",
    meta: { middleware: [auth, admin] },
    component: kmainCreate,
  },
  {
    path: "/lmaincreate",
    name: "lmainCreate",
    meta: { middleware: [auth, admin] },
    component: lmainCreate,
  },
  {
    path: "/mmaincreate",
    name: "mmainCreate",
    meta: { middleware: [auth, admin] },
    component: mmainCreate,
  },
  {
    path: "/nmaincreate",
    name: "nmainCreate",
    meta: { middleware: [auth, admin] },
    component: nmainCreate,
  },
  {
    path: "/omaincreate",
    name: "omainCreate",
    meta: { middleware: [auth, admin] },
    component: omainCreate,
  },
  {
    path: "/amainview/:id",
    name: "amainView",
    meta: { middleware: [auth, admin] },
    component: amainView,
  },
  {
    path: "/bmainview/:id",
    name: "bmainView",
    meta: { middleware: [auth, admin] },
    component: bmainView,
  },
  {
    path: "/cmainview/:id",
    name: "cmainView",
    meta: { middleware: [auth, admin] },
    component: cmainView,
  },
  {
    path: "/dmainview/:id",
    name: "dmainView",
    meta: { middleware: [auth, admin] },
    component: dmainView,
  },
  {
    path: "/emainview/:id",
    name: "emainView",
    meta: { middleware: [auth, admin] },
    component: emainView,
  },
  {
    path: "/fmainview/:id",
    name: "fmainView",
    meta: { middleware: [auth, admin] },
    component: fmainView,
  },
  {
    path: "/gmainview/:id",
    name: "gmainView",
    meta: { middleware: [auth, admin] },
    component: gmainView,
  },
  {
    path: "/hmainview/:id",
    name: "hmainView",
    meta: { middleware: [auth, admin] },
    component: hmainView,
  },
  {
    path: "/imainview/:id",
    name: "imainView",
    meta: { middleware: [auth, admin] },
    component: imainView,
  },
  {
    path: "/jmainview/:id",
    name: "jmainView",
    meta: { middleware: [auth, admin] },
    component: jmainView,
  },
  {
    path: "/kmainview/:id",
    name: "kmainView",
    meta: { middleware: [auth, admin] },
    component: kmainView,
  },
  {
    path: "/lmainview/:id",
    name: "lmainView",
    meta: { middleware: [auth, admin] },
    component: lmainView,
  },
  {
    path: "/mmainview/:id",
    name: "mmainView",
    meta: { middleware: [auth, admin] },
    component: mmainView,
  },
  {
    path: "/nmainview/:id",
    name: "nmainView",
    meta: { middleware: [auth, admin] },
    component: nmainView,
  },
  {
    path: "/omainview/:id",
    name: "omainView",
    meta: { middleware: [auth, admin] },
    component: omainView,
  },
  {
    path: "/amainedit/:id",
    name: "amainEdit",
    meta: { middleware: [auth, admin] },
    component: amainEdit,
  },
  {
    path: "/bmainedit/:id",
    name: "bmainEdit",
    meta: { middleware: [auth, admin] },
    component: bmainEdit,
  },
  {
    path: "/cmainedit/:id",
    name: "cmainEdit",
    meta: { middleware: [auth, admin] },
    component: cmainEdit,
  },
  {
    path: "/dmainedit/:id",
    name: "dmainEdit",
    meta: { middleware: [auth, admin] },
    component: dmainEdit,
  },
  {
    path: "/emainedit/:id",
    name: "emainEdit",
    meta: { middleware: [auth, admin] },
    component: emainEdit,
  },
  {
    path: "/fmainedit/:id",
    name: "fmainEdit",
    meta: { middleware: [auth, admin] },
    component: fmainEdit,
  },
  {
    path: "/gmainedit/:id",
    name: "gmainEdit",
    meta: { middleware: [auth, admin] },
    component: gmainEdit,
  },
  {
    path: "/hmainedit/:id",
    name: "hmainEdit",
    meta: { middleware: [auth, admin] },
    component: hmainEdit,
  },
  {
    path: "/imainedit/:id",
    name: "imainEdit",
    meta: { middleware: [auth, admin] },
    component: imainEdit,
  },
  {
    path: "/jmainedit/:id",
    name: "jmainEdit",
    meta: { middleware: [auth, admin] },
    component: jmainEdit,
  },
  {
    path: "/kmainedit/:id",
    name: "kmainEdit",
    meta: { middleware: [auth, admin] },
    component: kmainEdit,
  },
  {
    path: "/lmainedit/:id",
    name: "lmainEdit",
    meta: { middleware: [auth, admin] },
    component: lmainEdit,
  },
  {
    path: "/mmainedit/:id",
    name: "mmainEdit",
    meta: { middleware: [auth, admin] },
    component: mmainEdit,
  },
  {
    path: "/nmainedit/:id",
    name: "nmainEdit",
    meta: { middleware: [auth, admin] },
    component: nmainEdit,
  },
  {
    path: "/omainedit/:id",
    name: "omainEdit",
    meta: { middleware: [auth, admin] },
    component: omainEdit,
  },

  {
    path: "/fchart",
    name: "fchartIndex",
    meta: { middleware: [auth, famchart] },
    component: fchartIndex,
  },
  {
    path: "/fchartcreate",
    name: "fchartCreate",
    meta: { middleware: [auth, famchart] },
    component: fchartCreate,
  },
  {
    path: "/fchartview/:id",
    name: "fchartView",
    meta: { middleware: [auth, famchart] },
    component: fchartView,
  },
  {
    path: "/fchartedit/:id",
    name: "fchartEdit",
    meta: { middleware: [auth, famchart] },
    component: fchartEdit,
  },
  {
    path: "/fchartmember/:famId",
    name: "fchartMemberIndex",
    meta: { middleware: [auth, famchart] },
    component: fchartMemberIndex,
  },
  {
    path: "/fchartmembercreate/:famId",
    name: "fchartMemberCreate",
    meta: { middleware: [auth, famchart] },
    component: fchartMemberCreate,
  },
  {
    path: "/fchartmemberview/:famId/:id",
    name: "fchartMemberView",
    meta: { middleware: [auth, famchart] },
    component: fchartMemberView,
  },
  {
    path: "/fchartmemberedit/:famId/:id",
    name: "fchartMemberEdit",
    meta: { middleware: [auth, famchart] },
    component: fchartMemberEdit,
  },
  {
    path: "/fchartstatus/:famId",
    name: "fchartStatus",
    meta: { middleware: [auth, famchart] },
    component: fchartStatus,
  },
  {
    path: "/fchartdef/:famId",
    name: "fchartDef",
    meta: { middleware: [auth, famchart] },
    component: fchartDef,
  },
  {
    path: "/fchartadef/:famId",
    name: "fchartAdef",
    meta: { middleware: [auth, famchart] },
    component: fchartAdef,
  },
  {
    path: "/fchartbdef/:famId",
    name: "fchartBdef",
    meta: { middleware: [auth, famchart] },
    component: fchartBdef,
  },
  {
    path: "/fchartcdef/:famId",
    name: "fchartCdef",
    meta: { middleware: [auth, famchart] },
    component: fchartCdef,
  },
  {
    path: "/fchartddef/:famId",
    name: "fchartDdef",
    meta: { middleware: [auth, famchart] },
    component: fchartDdef,
  },
  {
    path: "/fchartedef/:famId",
    name: "fchartEdef",
    meta: { middleware: [auth, famchart] },
    component: fchartEdef,
  },
  {
    path: "/fchartfdef/:famId",
    name: "fchartFdef",
    meta: { middleware: [auth, famchart] },
    component: fchartFdef,
  },
  {
    path: "/fchartgdef/:famId",
    name: "fchartGdef",
    meta: { middleware: [auth, famchart] },
    component: fchartGdef,
  },
  {
    path: "/fcharthdef/:famId",
    name: "fchartHdef",
    meta: { middleware: [auth, famchart] },
    component: fchartHdef,
  },
  {
    path: "/fchartidef/:famId",
    name: "fchartIdef",
    meta: { middleware: [auth, famchart] },
    component: fchartIdef,
  },
  {
    path: "/fchartjdef/:famId",
    name: "fchartJdef",
    meta: { middleware: [auth, famchart] },
    component: fchartJdef,
  },
  {
    path: "/fchartkdef/:famId",
    name: "fchartKdef",
    meta: { middleware: [auth, famchart] },
    component: fchartKdef,
  },
  {
    path: "/fchartldef/:famId",
    name: "fchartLdef",
    meta: { middleware: [auth, famchart] },
    component: fchartLdef,
  },
  {
    path: "/fchartmdef/:famId",
    name: "fchartMdef",
    meta: { middleware: [auth, famchart] },
    component: fchartMdef,
  },
  {
    path: "/fchartndef/:famId",
    name: "fchartNdef",
    meta: { middleware: [auth, famchart] },
    component: fchartNdef,
  },
  {
    path: "/fchartodef/:famId",
    name: "fchartOdef",
    meta: { middleware: [auth, famchart] },
    component: fchartOdef,
  },
  {
    path: "/fchartinivitation/:famId",
    name: "fchartInvitation",
    meta: { middleware: [auth, famchart] },
    component: fchartInvitation,
  },
];

export default routes;
