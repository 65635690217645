import * as API from "@/services/API";

export default {
  getMain(Id) {
    return API.apiClient.get(`/hmain/${Id}`);
  },
  getMains() {
    return API.apiClient.get(`/hmain/`);
  },
  postMain(payload) {
    return API.apiClient.post(`/hmain/`, payload);
  },
  updateMain(Id, payload) {
    return API.apiClient.put(`/hmain/${Id}`, payload);
  },
  deleteMain(Id) {
    return API.apiClient.delete(`/hmain/${Id}`);
  },
};
