import * as API from "@/services/API";

export default {
  getAchartModuleResult(Id) {
    return API.apiClient.get(`/achartmoduleresult/${Id}`);
  },
  getBchartModuleResult(Id) {
    return API.apiClient.get(`/bchartmoduleresult/${Id}`);
  },
  getCchartModuleResult(Id) {
    return API.apiClient.get(`/cchartmoduleresult/${Id}`);
  },
  getDchartModuleResult(Id) {
    return API.apiClient.get(`/dchartmoduleresult/${Id}`);
  },
  getEchartModuleResult(Id) {
    return API.apiClient.get(`/echartmoduleresult/${Id}`);
  },
  getFchartModuleResult(Id) {
    return API.apiClient.get(`/fchartmoduleresult/${Id}`);
  },
  getGchartModuleResult(Id) {
    return API.apiClient.get(`/gchartmoduleresult/${Id}`);
  },
  getHchartModuleResult(Id) {
    return API.apiClient.get(`/hchartmoduleresult/${Id}`);
  },
  getIchartModuleResult(Id) {
    return API.apiClient.get(`/ichartmoduleresult/${Id}`);
  },
  getJchartModuleResult(Id) {
    return API.apiClient.get(`/jchartmoduleresult/${Id}`);
  },
  getKchartModuleResult(Id) {
    return API.apiClient.get(`/kchartmoduleresult/${Id}`);
  },
  getLchartModuleResult(Id) {
    return API.apiClient.get(`/lchartmoduleresult/${Id}`);
  },
  getMchartModuleResult(Id) {
    return API.apiClient.get(`/mchartmoduleresult/${Id}`);
  },
  getNchartModuleResult(Id) {
    return API.apiClient.get(`/nchartmoduleresult/${Id}`);
  },
  getOchartModuleResult(Id) {
    return API.apiClient.get(`/ochartmoduleresult/${Id}`);
  },
};
