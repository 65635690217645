import * as API from "@/services/API";

export default {
  getMain(Id) {
    return API.apiClient.get(`/kmain/${Id}`);
  },
  getMains() {
    return API.apiClient.get(`/kmain/`);
  },
  postMain(payload) {
    return API.apiClient.post(`/kmain/`, payload);
  },
  updateMain(Id, payload) {
    return API.apiClient.put(`/kmain/${Id}`, payload);
  },
  deleteMain(Id) {
    return API.apiClient.delete(`/kmain/${Id}`);
  },
};
