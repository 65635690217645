import * as API from "@/services/API";

export default {
  getMain(Id) {
    return API.apiClient.get(`/lmain/${Id}`);
  },
  getMains() {
    return API.apiClient.get(`/lmain/`);
  },
  postMain(payload) {
    return API.apiClient.post(`/lmain/`, payload);
  },
  updateMain(Id, payload) {
    return API.apiClient.put(`/lmain/${Id}`, payload);
  },
  deleteMain(Id) {
    return API.apiClient.delete(`/lmain/${Id}`);
  },
};
