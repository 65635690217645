import auth from "@/middleware/auth";

const statuteIndex = () =>
  import(/* webpackChunkName: "statuteI" */ "../views/Statute/index");

const statuteView = () =>
  import(/* webpackChunkName: "statuteV" */ "../views/Statute/view");

const statuteEdit = () =>
  import(/* webpackChunkName: "statuteE" */ "../views/Statute/edit");

const statuteCreate = () =>
  import(/* webpackChunkName: "statuteC" */ "../views/Statute/create");

const statuteModules = () =>
  import(
    /* webpackChunkName: "statuteModules" */ "../views/Statute/Modules/index"
  );

const statuteModuleAdjustA = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustA" */ "../views/Statute/Modules/module-a"
  );

const statuteModuleAdjustB = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustB" */ "../views/Statute/Modules/module-b"
  );

const statuteMemberIndex = () =>
  import(
    /* webpackChunkName: "statuteMemberI" */ "@/views/Statute/member/index"
  );

const statuteMemberCreate = () =>
  import(
    /* webpackChunkName: "statuteMemberC" */ "@/views/Statute/member/create"
  );

const statuteMemberEdit = () =>
  import(
    /* webpackChunkName: "statuteMemberI" */ "@/views/Statute/member/edit"
  );
const statuteMemberView = () =>
  import(
    /* webpackChunkName: "statuteMemberC" */ "@/views/Statute/member/view"
  );
const statuteInvitation = () =>
  import(
    /* webpackChunkName: "statuteInvitation" */ "@/views/Statute/member/invitation"
  );
const statuteStatus = () =>
  import(/* webpackChunkName: "statuteStatus" */ "@/views/Statute/status");

const statuteDefIndex = () =>
  import(/* webpackChunkName: "statuteDefIndex" */ "@/views/Statute/def/index");

const statuteDefB = () =>
  import(
    /* webpackChunkName: "statuteDefB" */ "@/views/Statute/def/statuteDefB"
  );

const routes = [
  {
    path: "/statute",
    name: "statuteIndex",
    meta: { middleware: [auth] },
    component: statuteIndex,
  },
  {
    path: "/statute-view/:id",
    name: "statuteView",
    meta: { middleware: [auth] },
    component: statuteView,
  },
  {
    path: "/statute-edit/:id",
    name: "statuteEdit",
    meta: { middleware: [auth] },
    component: statuteEdit,
  },
  {
    path: "/statute-create",
    name: "statuteCreate",
    meta: { middleware: [auth] },
    component: statuteCreate,
  },
  {
    path: "/statute-modules/:famId",
    name: "statuteModules",
    meta: { middleware: [auth] },
    component: statuteModules,
  },
  {
    path: "/statute-module-a-adjust/:famId",
    name: "statuteModuleAdjustA",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustA,
  },
  {
    path: "/statute-module-b-adjust/:famId",
    name: "statuteModuleAdjustB",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustB,
  },
  {
    path: "/statute-member-index/:famId",
    name: "statuteMemberIndex",
    meta: { middleware: [auth] },
    component: statuteMemberIndex,
  },
  {
    path: "/statute-member-create/:famId",
    name: "statuteMemberCreate",
    meta: { middleware: [auth] },
    component: statuteMemberCreate,
  },
  {
    path: "/statute-member-view/:famId/:id",
    name: "statuteMemberView",
    meta: { middleware: [auth] },
    component: statuteMemberView,
  },
  {
    path: "/statute-member-edit/:famId/:id",
    name: "statuteMemberEdit",
    meta: { middleware: [auth] },
    component: statuteMemberEdit,
  },
  {
    path: "/statute-invitation/:famId",
    name: "statuteInvitation",
    meta: { middleware: [auth] },
    component: statuteInvitation,
  },
  {
    path: "/statute-status/:famId",
    name: "statuteStatus",
    meta: { middleware: [auth] },
    component: statuteStatus,
  },
  {
    path: "/statute-definitive/:famId",
    name: "statuteDefIndex",
    meta: { middleware: [auth] },
    component: statuteDefIndex,
  },
  {
    path: "/statute-def-b/:famId",
    name: "statuteDefB",
    meta: { middleware: [auth] },
    component: statuteDefB,
  },
];
export default routes;
