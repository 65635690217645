import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

const teamsIndex = () =>
  import("../views/Teams/index" /* webpackChunkName: "teamI" */);
const teamsView = () =>
  import("../views/Teams/view" /* webpackChunkName: "teamV" */);
const teamCreate = () =>
  import("../views/Teams/create" /* webpackChunkName: "teamC" */);
const teamEdit = () =>
  import("../views/Teams/edit" /* webpackChunkName: "teamE" */);

const routes = [
  {
    path: "/teams",
    component: teamsIndex,
    name: "teamsIndex",
    meta: { middleware: [auth, admin] },
  },
  {
    path: "/teamview/:id",
    name: "teamsView",
    meta: { middleware: [auth, admin] },
    component: teamsView,
  },
  {
    path: "/teamscreate",
    name: "teamsCreate",
    meta: { middleware: [auth, admin] },
    component: teamCreate,
  },
  {
    path: "/teamsedit/:id",
    name: "teamsEdit",
    meta: { middleware: [auth, admin] },
    component: teamEdit,
  },
];
export default routes;
