import Vue from "vue";
import VueI18n from "vue-i18n";
import nl from "vee-validate/dist/locale/nl.json";
import en from "vee-validate/dist/locale/en.json";
import nl2 from "./locales/nl2.json";
import en2 from "./locales/en2.json";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "nl",
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  messages: {
    nl: {
      login: nl2.login,
      test: nl2.test,
      nav: nl2.nav,
      users: nl2.users,
      crud: nl2.crud,
      team: nl2.team,
      gender: nl2.gender,
      defchart: nl2.defchart,
      main: nl2.main,
      fchart: nl2.fchart,
      mod1stat3: nl2.mod1stat3,
      mod1stat4: nl2.mod1stat4,
      mod1stat6: nl2.mod1stat6,
      mod1stat19: nl2.mod1stat19,
      mod1stat20: nl2.mod1stat20,
      mod1stat21: nl2.mod1stat21,
      mod1stat22: nl2.mod1stat22,
      fields: {
        email: "e-mail",
        password: "Wachtwoord",
        code: "Twee factor code",
        teamnaam: "Naam van het team",
        company: "Bedrijfsnaam",
        Bedrijfsnaam: "Bedrijfsnaam",
      },
      validation: nl.messages,
    },
    en: {
      login: en2.login,
      test: en2.test,
      nav: en2.nav,
      users: en2.users,
      crud: en2.crud,
      team: en2.team,
      gender: en2.gender,
      defchart: en2.defchart,
      main: en2.main,
      fchart: en2.fchart,
      mod1stat3: en2.mod1stat3,
      mod1stat4: en2.mod1stat4,
      mod1stat6: en2.mod1stat6,
      mod1stat19: en2.mod1stat19,
      mod1stat20: en2.mod1stat20,
      mod1stat21: en2.mod1stat21,
      mod1stat22: en2.mod1stat22,
      fields: {
        email: "e-mail",
        password: "Password",
        code: "Two factor code",
        teamnaam: "Team name",
        company: "Company",
        Bedrijfsnaam: "Company name",
      },
      validation: en.messages,
    },
  },
});

export { i18n };
