import * as API from "@/services/API";

export default {
  getUser(userId) {
    return API.apiClient.get(`/users/${userId}`);
  },
  getUsers() {
    return API.apiClient.get(`/users/`);
  },
  postUser(payload) {
    return API.apiClient.post(`/users/`, payload);
  },
  updateUser(userId, payload) {
    return API.apiClient.put(`/users/${userId}`, payload);
  },
  deleteUser(userId) {
    return API.apiClient.delete(`/users/${userId}`);
  },
  updatePW(userId, payload) {
    return API.apiClient.put(`/users/${userId}`, payload);
  },
};
