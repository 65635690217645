import * as API from "@/services/API";
import * as API2 from "@/services/API2";
export default {
  getMain(Id) {
    return API.apiClient.get(`/npBackBv/${Id}`);
  },
  getMains() {
    return API.apiClient.get(`/npBackBv/`);
  },
  postMain(payload) {
    return API.apiClient.post(`/npBackBv/`, payload);
  },
  updateMain(Id, payload) {
    return API.apiClient.put(`/npBackBv/${Id}`, payload);
  },
  deleteMain(Id) {
    return API.apiClient.delete(`/npBackBv/${Id}`);
  },
  sendInvitation(Id) {
    return API.apiClient.get(`/npBackBvSendInv/${Id}`);
  },
  updateModA(Id, payload) {
    return API.apiClient.post(`/npBackBvModA/${Id}`, payload);
  },
  updateModB(Id, payload) {
    return API.apiClient.post(`/npBackBvModB/${Id}`, payload);
  },
  updateModC(Id, payload) {
    return API.apiClient.post(`/npBackBvModC/${Id}`, payload);
  },
  updateModD(Id, payload) {
    return API.apiClient.post(`/npBackBvModD/${Id}`, payload);
  },
  updateModE(Id, payload) {
    return API.apiClient.post(`/npBackBvModE/${Id}`, payload);
  },
  updateModF(Id, payload) {
    return API.apiClient.post(`/npBackBvModF/${Id}`, payload);
  },
  updateModG(Id, payload) {
    return API.apiClient.post(`/npBackBvModG/${Id}`, payload);
  },
  updateModH(Id, payload) {
    return API.apiClient.post(`/npBackBvModH/${Id}`, payload);
  },
  updateModI(Id, payload) {
    return API.apiClient.post(`/npBackBvModI/${Id}`, payload);
  },
  updateModJ(Id, payload) {
    return API.apiClient.post(`/npBackBvModJ/${Id}`, payload);
  },
  updateModK(Id, payload) {
    return API.apiClient.post(`/npBackBvModK/${Id}`, payload);
  },
  updateModL(Id, payload) {
    return API.apiClient.post(`/npBackBvModL/${Id}`, payload);
  },
  updateModM(Id, payload) {
    return API.apiClient.post(`/npBackBvModM/${Id}`, payload);
  },
  updateModN(Id, payload) {
    return API.apiClient.post(`/npBackBvModN/${Id}`, payload);
  },
  getReportFormalA(Id) {
    return API2.apiClient.post(`/printBV_A_formal/${Id}`);
  },
  getReportInFormalA(Id) {
    return API2.apiClient.post(`/printBV_A_informal/${Id}`);
  },
  getReportFormalB(Id) {
    return API2.apiClient.post(`/printBV_B_formal/${Id}`);
  },
  getReportInFormalB(Id) {
    return API2.apiClient.post(`/printBV_B_informal/${Id}`);
  },
};
