import { getError } from "@/utils/helpers";
import FchartResultService from "@/services/fchart/FchartResultService";

export const namespaced = true;

export const state = {
  kstoremodules: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_ASTORE(state, kstoremodules) {
    state.kstoremodules = kstoremodules;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getAmodule({ commit }, id) {
    commit("SET_LOADING", true);

    FchartResultService.getKchartModuleResult(id)
      .then((response) => {
        commit("SET_ASTORE", response.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  setDefault({ commit }) {
    commit("SET_LOADING", false);
    commit("SET_ERROR", null);
    commit("SET_STATEMENTS", []);
  },
};
export const getters = {
  kstoremodules: (state) => {
    return state.kstoremodules;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
