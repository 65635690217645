import { getError } from "@/utils/helpers";
import StatuteService from "@/services/statute/StatuteService";

export const namespaced = true;

export const state = {
  section_1: "",
  section_2: "",
  section_3: "",
  section_4: "",
  section_5: "",
  section_6: "",
  section_7: "",
  section_8: "",
  section_9: "",
  section_10: "",
  section_11: "",
  section_12: "",
  statement_1: "",
  statement_2: "",
  statement_3: "",
  statement_4: "",
  statement_5: "",
  statement_6: "",
  statement_7: "",
  statement_8: "",
  statement_9: "",
  statement_10: "",
  statement_11: "",
  statement_12: "",
  statement_13: "",
  statement_14: "",
  statement_15: "",
  statement_16: "",
  statement_17: "",
  statement_18: "",
  statement_19: "",
  statement_20: "",
  statement_21: "",
  statement_22: "",
  statement_23: "",
  statement_24: "",
  statement_25: "",
  statement_26: "",
  statement_27: "",
  statement_28: "",
  statement_29: "",
  statement_30: "",
  statement_31: "",
  statement_32: "",
  statement_33: "",
  statement_34: "",
  statement_35: "",
  statement_36: "",
  statement_37: "",
  statement_38: "",
  statement_39: "",
  statement_40: "",
  statement_41: "",
  statement_42: "",
  statement_43: "",
  statement_44: "",
  statement_45: "",
  statement_46: "",
  statement_47: "",
  statement_48: "",
  statement_49: "",
  statement_50: "",
  loading: false,
  error: null,
};

export const mutations = {
  update_section_1(state, section_1) {
    state.section_1 = section_1;
  },
  update_section_2(state, section_2) {
    state.section_2 = section_2;
  },
  update_section_3(state, section_3) {
    state.section_3 = section_3;
  },
  update_section_4(state, section_4) {
    state.section_4 = section_4;
  },
  update_section_5(state, section_5) {
    state.section_5 = section_5;
  },
  update_section_6(state, section_6) {
    state.section_6 = section_6;
  },
  update_section_7(state, section_7) {
    state.section_7 = section_7;
  },
  update_section_8(state, section_8) {
    state.section_8 = section_8;
  },
  update_section_9(state, section_9) {
    state.section_9 = section_9;
  },
  update_section_10(state, section_10) {
    state.section_10 = section_10;
  },
  update_section_11(state, section_11) {
    state.section_11 = section_11;
  },
  update_section_12(state, section_12) {
    state.section_12 = section_12;
  },
  update_statement_1(state, statement_1) {
    state.statement_1 = statement_1;
  },
  update_statement_2(state, statement_2) {
    state.statement_2 = statement_2;
  },
  update_statement_3(state, statement_3) {
    state.statement_3 = statement_3;
  },
  update_statement_4(state, statement_4) {
    state.statement_4 = statement_4;
  },
  update_statement_5(state, statement_5) {
    state.statement_5 = statement_5;
  },
  update_statement_6(state, statement_6) {
    state.statement_6 = statement_6;
  },
  update_statement_7(state, statement_7) {
    state.statement_7 = statement_7;
  },
  update_statement_8(state, statement_8) {
    state.statement_8 = statement_8;
  },
  update_statement_9(state, statement_9) {
    state.statement_9 = statement_9;
  },
  update_statement_10(state, statement_10) {
    state.statement_10 = statement_10;
  },
  update_statement_11(state, statement_11) {
    state.statement_11 = statement_11;
  },
  update_statement_12(state, statement_12) {
    state.statement_12 = statement_12;
  },
  update_statement_13(state, statement_13) {
    state.statement_13 = statement_13;
  },
  update_statement_14(state, statement_14) {
    state.statement_14 = statement_14;
  },
  update_statement_15(state, statement_15) {
    state.statement_15 = statement_15;
  },
  update_statement_16(state, statement_16) {
    state.statement_16 = statement_16;
  },
  update_statement_17(state, statement_17) {
    state.statement_17 = statement_17;
  },
  update_statement_18(state, statement_18) {
    state.statement_18 = statement_18;
  },
  update_statement_19(state, statement_19) {
    state.statement_19 = statement_19;
  },
  update_statement_20(state, statement_20) {
    state.statement_20 = statement_20;
  },
  update_statement_21(state, statement_21) {
    state.statement_21 = statement_21;
  },
  update_statement_22(state, statement_22) {
    state.statement_22 = statement_22;
  },
  update_statement_23(state, statement_23) {
    state.statement_23 = statement_23;
  },
  update_statement_24(state, statement_24) {
    state.statement_24 = statement_24;
  },
  update_statement_25(state, statement_25) {
    state.statement_25 = statement_25;
  },
  update_statement_26(state, statement_26) {
    state.statement_26 = statement_26;
  },
  update_statement_27(state, statement_27) {
    state.statement_27 = statement_27;
  },
  update_statement_28(state, statement_28) {
    state.statement_28 = statement_28;
  },
  update_statement_29(state, statement_29) {
    state.statement_29 = statement_29;
  },
  update_statement_30(state, statement_30) {
    state.statement_30 = statement_30;
  },
  update_statement_31(state, statement_31) {
    state.statement_31 = statement_31;
  },
  update_statement_32(state, statement_32) {
    state.statement_32 = statement_32;
  },
  update_statement_33(state, statement_33) {
    state.statement_33 = statement_33;
  },
  update_statement_34(state, statement_34) {
    state.statement_34 = statement_34;
  },
  update_statement_35(state, statement_35) {
    state.statement_35 = statement_35;
  },
  update_statement_36(state, statement_36) {
    state.statement_36 = statement_36;
  },
  update_statement_37(state, statement_37) {
    state.statement_37 = statement_37;
  },
  update_statement_38(state, statement_38) {
    state.statement_38 = statement_38;
  },
  update_statement_39(state, statement_39) {
    state.statement_39 = statement_39;
  },
  update_statement_40(state, statement_40) {
    state.statement_40 = statement_40;
  },
  update_statement_41(state, statement_41) {
    state.statement_41 = statement_41;
  },
  update_statement_42(state, statement_42) {
    state.statement_42 = statement_42;
  },
  update_statement_43(state, statement_43) {
    state.statement_43 = statement_43;
  },
  update_statement_44(state, statement_44) {
    state.statement_44 = statement_44;
  },
  update_statement_45(state, statement_45) {
    state.statement_45 = statement_45;
  },
  update_statement_46(state, statement_46) {
    state.statement_46 = statement_46;
  },
  update_statement_47(state, statement_47) {
    state.statement_47 = statement_47;
  },
  update_statement_48(state, statement_48) {
    state.statement_48 = statement_48;
  },
  update_statement_49(state, statement_49) {
    state.statement_49 = statement_49;
  },
  update_statement_50(state, statement_50) {
    state.statement_50 = statement_50;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const getters = {
  section_1: (state) => {
    return state.section_1;
  },
  section_2: (state) => {
    return state.section_2;
  },
  section_3: (state) => {
    return state.section_3;
  },
  section_4: (state) => {
    return state.section_4;
  },
  section_5: (state) => {
    return state.section_5;
  },
  section_6: (state) => {
    return state.section_6;
  },
  section_7: (state) => {
    return state.section_7;
  },
  section_8: (state) => {
    return state.section_8;
  },
  section_9: (state) => {
    return state.section_9;
  },
  section_10: (state) => {
    return state.section_10;
  },
  section_11: (state) => {
    return state.section_11;
  },
  section_12: (state) => {
    return state.section_12;
  },
  statement_1: (state) => {
    return state.statement_1;
  },
  statement_2: (state) => {
    return state.statement_2;
  },
  statement_3: (state) => {
    return state.statement_3;
  },
  statement_4: (state) => {
    return state.statement_4;
  },
  statement_5: (state) => {
    return state.statement_5;
  },
  statement_6: (state) => {
    return state.statement_6;
  },
  statement_7: (state) => {
    return state.statement_7;
  },
  statement_8: (state) => {
    return state.statement_8;
  },
  statement_9: (state) => {
    return state.statement_9;
  },
  statement_10: (state) => {
    return state.statement_10;
  },
  statement_11: (state) => {
    return state.statement_11;
  },
  statement_12: (state) => {
    return state.statement_12;
  },
  statement_13: (state) => {
    return state.statement_13;
  },
  statement_14: (state) => {
    return state.statement_14;
  },
  statement_15: (state) => {
    return state.statement_15;
  },
  statement_16: (state) => {
    return state.statement_16;
  },
  statement_17: (state) => {
    return state.statement_17;
  },
  statement_18: (state) => {
    return state.statement_18;
  },
  statement_19: (state) => {
    return state.statement_19;
  },
  statement_20: (state) => {
    return state.statement_20;
  },
  statement_21: (state) => {
    return state.statement_21;
  },
  statement_22: (state) => {
    return state.statement_22;
  },
  statement_23: (state) => {
    return state.statement_23;
  },
  statement_24: (state) => {
    return state.statement_24;
  },
  statement_25: (state) => {
    return state.statement_25;
  },
  statement_26: (state) => {
    return state.statement_26;
  },
  statement_27: (state) => {
    return state.statement_27;
  },
  statement_28: (state) => {
    return state.statement_28;
  },
  statement_29: (state) => {
    return state.statement_29;
  },
  statement_30: (state) => {
    return state.statement_30;
  },
  statement_31: (state) => {
    return state.statement_31;
  },
  statement_32: (state) => {
    return state.statement_32;
  },
  statement_33: (state) => {
    return state.statement_33;
  },
  statement_34: (state) => {
    return state.statement_34;
  },
  statement_35: (state) => {
    return state.statement_35;
  },
  statement_36: (state) => {
    return state.statement_36;
  },
  statement_37: (state) => {
    return state.statement_37;
  },
  statement_38: (state) => {
    return state.statement_38;
  },
  statement_39: (state) => {
    return state.statement_39;
  },
  statement_40: (state) => {
    return state.statement_40;
  },
  statement_41: (state) => {
    return state.statement_41;
  },
  statement_42: (state) => {
    return state.statement_42;
  },
  statement_43: (state) => {
    return state.statement_43;
  },
  statement_44: (state) => {
    return state.statement_44;
  },
  statement_45: (state) => {
    return state.statement_45;
  },
  statement_46: (state) => {
    return state.statement_46;
  },
  statement_47: (state) => {
    return state.statement_47;
  },
  statement_48: (state) => {
    return state.statement_48;
  },
  statement_49: (state) => {
    return state.statement_49;
  },
  statement_50: (state) => {
    return state.statement_50;
  },

  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
export const actions = {
  async AdjustA({ commit }, famId) {
    commit("SET_LOADING", true);
    try {
      const response = await StatuteService.showAdjustA(famId);
      commit("update_section_1", response.data.data.section_1);
      commit("update_section_2", response.data.data.section_2);
      commit("update_section_3", response.data.data.section_3);
      commit("update_section_4", response.data.data.section_4);
      commit("update_section_5", response.data.data.section_5);
      commit("update_section_6", response.data.data.section_6);
      commit("update_section_7", response.data.data.section_7);
      commit("update_section_8", response.data.data.section_8);
      commit("update_section_9", response.data.data.section_9);
      commit("update_section_10", response.data.data.section_10);
      commit("update_section_11", response.data.data.section_11);
      commit("update_section_12", response.data.data.section_12);
      commit("update_statement_1", response.data.data.statement_1);
      commit("update_statement_2", response.data.data.statement_2);
      commit("update_statement_3", response.data.data.statement_3);
      commit("update_statement_4", response.data.data.statement_4);
      commit("update_statement_5", response.data.data.statement_5);
      commit("update_statement_6", response.data.data.statement_6);
      commit("update_statement_7", response.data.data.statement_7);
      commit("update_statement_8", response.data.data.statement_8);
      commit("update_statement_9", response.data.data.statement_9);
      commit("update_statement_10", response.data.data.statement_10);
      commit("update_statement_11", response.data.data.statement_11);
      commit("update_statement_12", response.data.data.statement_12);
      commit("update_statement_13", response.data.data.statement_13);
      commit("update_statement_14", response.data.data.statement_14);
      commit("update_statement_15", response.data.data.statement_15);
      commit("update_statement_16", response.data.data.statement_16);
      commit("update_statement_17", response.data.data.statement_17);
      commit("update_statement_18", response.data.data.statement_18);
      commit("update_statement_19", response.data.data.statement_19);
      commit("update_statement_20", response.data.data.statement_20);
      commit("update_statement_21", response.data.data.statement_21);
      commit("update_statement_22", response.data.data.statement_22);
      commit("update_statement_23", response.data.data.statement_23);
      commit("update_statement_24", response.data.data.statement_24);
      commit("update_statement_25", response.data.data.statement_25);
      commit("update_statement_26", response.data.data.statement_26);
      commit("update_statement_27", response.data.data.statement_27);
      commit("update_statement_28", response.data.data.statement_28);
      commit("update_statement_29", response.data.data.statement_29);
      commit("update_statement_30", response.data.data.statement_30);
      commit("update_statement_31", response.data.data.statement_31);
      commit("update_statement_32", response.data.data.statement_32);
      commit("update_statement_33", response.data.data.statement_33);
      commit("update_statement_34", response.data.data.statement_34);
      commit("update_statement_35", response.data.data.statement_35);
      commit("update_statement_36", response.data.data.statement_36);
      commit("update_statement_37", response.data.data.statement_37);
      commit("update_statement_38", response.data.data.statement_38);
      commit("update_statement_39", response.data.data.statement_39);
      commit("update_statement_40", response.data.data.statement_40);
      commit("update_statement_41", response.data.data.statement_41);
      commit("update_statement_42", response.data.data.statement_42);
      commit("update_statement_43", response.data.data.statement_43);
      commit("update_statement_44", response.data.data.statement_44);
      commit("update_statement_45", response.data.data.statement_45);
      commit("update_statement_46", response.data.data.statement_46);
      commit("update_statement_47", response.data.data.statement_47);
      commit("update_statement_48", response.data.data.statement_48);
      commit("update_statement_49", response.data.data.statement_49);
      commit("update_statement_50", response.data.data.statement_50);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
    }
  },
  async AdjustB({ commit }, famId) {
    commit("SET_LOADING", true);
    try {
      const response = await StatuteService.showAdjustB(famId);
      commit("update_section_1", response.data.data.section_1);
      commit("update_section_2", response.data.data.section_2);
      commit("update_section_3", response.data.data.section_3);
      commit("update_section_4", response.data.data.section_4);
      commit("update_section_5", response.data.data.section_5);
      commit("update_section_6", response.data.data.section_6);
      commit("update_section_7", response.data.data.section_7);
      commit("update_section_8", response.data.data.section_8);
      commit("update_section_9", response.data.data.section_9);
      commit("update_section_10", response.data.data.section_10);
      commit("update_section_11", response.data.data.section_11);
      commit("update_section_12", response.data.data.section_12);
      commit("update_statement_1", response.data.data.statement_1);
      commit("update_statement_2", response.data.data.statement_2);
      commit("update_statement_3", response.data.data.statement_3);
      commit("update_statement_4", response.data.data.statement_4);
      commit("update_statement_5", response.data.data.statement_5);
      commit("update_statement_6", response.data.data.statement_6);
      commit("update_statement_7", response.data.data.statement_7);
      commit("update_statement_8", response.data.data.statement_8);
      commit("update_statement_9", response.data.data.statement_9);
      commit("update_statement_10", response.data.data.statement_10);
      commit("update_statement_11", response.data.data.statement_11);
      commit("update_statement_12", response.data.data.statement_12);
      commit("update_statement_13", response.data.data.statement_13);
      commit("update_statement_14", response.data.data.statement_14);
      commit("update_statement_15", response.data.data.statement_15);
      commit("update_statement_16", response.data.data.statement_16);
      commit("update_statement_17", response.data.data.statement_17);
      commit("update_statement_18", response.data.data.statement_18);
      commit("update_statement_19", response.data.data.statement_19);
      commit("update_statement_20", response.data.data.statement_20);
      commit("update_statement_21", response.data.data.statement_21);
      commit("update_statement_22", response.data.data.statement_22);
      commit("update_statement_23", response.data.data.statement_23);
      commit("update_statement_24", response.data.data.statement_24);
      commit("update_statement_25", response.data.data.statement_25);
      commit("update_statement_26", response.data.data.statement_26);
      commit("update_statement_27", response.data.data.statement_27);
      commit("update_statement_28", response.data.data.statement_28);
      commit("update_statement_29", response.data.data.statement_29);
      commit("update_statement_30", response.data.data.statement_30);
      commit("update_statement_31", response.data.data.statement_31);
      commit("update_statement_32", response.data.data.statement_32);
      commit("update_statement_33", response.data.data.statement_33);
      commit("update_statement_34", response.data.data.statement_34);
      commit("update_statement_35", response.data.data.statement_35);
      commit("update_statement_36", response.data.data.statement_36);
      commit("update_statement_37", response.data.data.statement_37);
      commit("update_statement_38", response.data.data.statement_38);
      commit("update_statement_39", response.data.data.statement_39);
      commit("update_statement_40", response.data.data.statement_40);
      commit("update_statement_41", response.data.data.statement_41);
      commit("update_statement_42", response.data.data.statement_42);
      commit("update_statement_43", response.data.data.statement_43);
      commit("update_statement_44", response.data.data.statement_44);
      commit("update_statement_45", response.data.data.statement_45);
      commit("update_statement_46", response.data.data.statement_46);
      commit("update_statement_47", response.data.data.statement_47);
      commit("update_statement_48", response.data.data.statement_48);
      commit("update_statement_49", response.data.data.statement_49);
      commit("update_statement_50", response.data.data.statement_50);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
    }
  },
  async AdjustC({ commit }, famId) {
    commit("SET_LOADING", true);
    try {
      const response = await StatuteService.showAdjustC(famId);
      commit("update_section_1", response.data.data.section_1);
      commit("update_section_2", response.data.data.section_2);
      commit("update_section_3", response.data.data.section_3);
      commit("update_section_4", response.data.data.section_4);
      commit("update_section_5", response.data.data.section_5);
      commit("update_section_6", response.data.data.section_6);
      commit("update_section_7", response.data.data.section_7);
      commit("update_section_8", response.data.data.section_8);
      commit("update_section_9", response.data.data.section_9);
      commit("update_section_10", response.data.data.section_10);
      commit("update_section_11", response.data.data.section_11);
      commit("update_section_12", response.data.data.section_12);
      commit("update_statement_1", response.data.data.statement_1);
      commit("update_statement_2", response.data.data.statement_2);
      commit("update_statement_3", response.data.data.statement_3);
      commit("update_statement_4", response.data.data.statement_4);
      commit("update_statement_5", response.data.data.statement_5);
      commit("update_statement_6", response.data.data.statement_6);
      commit("update_statement_7", response.data.data.statement_7);
      commit("update_statement_8", response.data.data.statement_8);
      commit("update_statement_9", response.data.data.statement_9);
      commit("update_statement_10", response.data.data.statement_10);
      commit("update_statement_11", response.data.data.statement_11);
      commit("update_statement_12", response.data.data.statement_12);
      commit("update_statement_13", response.data.data.statement_13);
      commit("update_statement_14", response.data.data.statement_14);
      commit("update_statement_15", response.data.data.statement_15);
      commit("update_statement_16", response.data.data.statement_16);
      commit("update_statement_17", response.data.data.statement_17);
      commit("update_statement_18", response.data.data.statement_18);
      commit("update_statement_19", response.data.data.statement_19);
      commit("update_statement_20", response.data.data.statement_20);
      commit("update_statement_21", response.data.data.statement_21);
      commit("update_statement_22", response.data.data.statement_22);
      commit("update_statement_23", response.data.data.statement_23);
      commit("update_statement_24", response.data.data.statement_24);
      commit("update_statement_25", response.data.data.statement_25);
      commit("update_statement_26", response.data.data.statement_26);
      commit("update_statement_27", response.data.data.statement_27);
      commit("update_statement_28", response.data.data.statement_28);
      commit("update_statement_29", response.data.data.statement_29);
      commit("update_statement_30", response.data.data.statement_30);
      commit("update_statement_31", response.data.data.statement_31);
      commit("update_statement_32", response.data.data.statement_32);
      commit("update_statement_33", response.data.data.statement_33);
      commit("update_statement_34", response.data.data.statement_34);
      commit("update_statement_35", response.data.data.statement_35);
      commit("update_statement_36", response.data.data.statement_36);
      commit("update_statement_37", response.data.data.statement_37);
      commit("update_statement_38", response.data.data.statement_38);
      commit("update_statement_39", response.data.data.statement_39);
      commit("update_statement_40", response.data.data.statement_40);
      commit("update_statement_41", response.data.data.statement_41);
      commit("update_statement_42", response.data.data.statement_42);
      commit("update_statement_43", response.data.data.statement_43);
      commit("update_statement_44", response.data.data.statement_44);
      commit("update_statement_45", response.data.data.statement_45);
      commit("update_statement_46", response.data.data.statement_46);
      commit("update_statement_47", response.data.data.statement_47);
      commit("update_statement_48", response.data.data.statement_48);
      commit("update_statement_49", response.data.data.statement_49);
      commit("update_statement_50", response.data.data.statement_50);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
    }
  },

  setDefault({ commit }) {
    commit("SET_LOADING", false);
    commit("SET_ERROR", null);
    commit("update_section_1", "");
    commit("update_section_2", "");
    commit("update_section_3", "");
    commit("update_section_4", "");
    commit("update_section_5", "");
    commit("update_section_6", "");
    commit("update_section_7", "");
    commit("update_section_8", "");
    commit("update_section_9", "");
    commit("update_section_10", "");
    commit("update_section_11", "");
    commit("update_section_12", "");
    commit("update_statement_1", "");
    commit("update_statement_2", "");
    commit("update_statement_3", "");
    commit("update_statement_4", "");
    commit("update_statement_5", "");
    commit("update_statement_6", "");
    commit("update_statement_7", "");
    commit("update_statement_8", "");
    commit("update_statement_9", "");
    commit("update_statement_10", "");
    commit("update_statement_11", "");
    commit("update_statement_12", "");
    commit("update_statement_13", "");
    commit("update_statement_14", "");
    commit("update_statement_15", "");
    commit("update_statement_16", "");
    commit("update_statement_17", "");
    commit("update_statement_18", "");
    commit("update_statement_19", "");
    commit("update_statement_20", "");
    commit("update_statement_21", "");
    commit("update_statement_22", "");
    commit("update_statement_23", "");
    commit("update_statement_24", "");
    commit("update_statement_25", "");
    commit("update_statement_26", "");
    commit("update_statement_27", "");
    commit("update_statement_28", "");
    commit("update_statement_29", "");
    commit("update_statement_30", "");
    commit("update_statement_31", "");
    commit("update_statement_32", "");
    commit("update_statement_33", "");
    commit("update_statement_34", "");
    commit("update_statement_35", "");
    commit("update_statement_36", "");
    commit("update_statement_37", "");
    commit("update_statement_38", "");
    commit("update_statement_39", "");
    commit("update_statement_40", "");
    commit("update_statement_41", "");
    commit("update_statement_42", "");
    commit("update_statement_43", "");
    commit("update_statement_44", "");
    commit("update_statement_45", "");
    commit("update_statement_46", "");
    commit("update_statement_47", "");
    commit("update_statement_48", "");
    commit("update_statement_49", "");
    commit("update_statement_50", "");
  },
};
