import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import admin from "@/middleware/admin";

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   meta: { middleware: [guest] },
  //   component: () => import(/* webpackChunkName: "home" */ "../views/Home"),
  // },
  {
    path: "/",
    name: "login",
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
  },
  {
    path: "/twofactor",
    name: "twofactor",
    meta: { middleware: [auth] },
    component: () =>
      import(/* webpackChunkName: "twofactor" */ "../views/TwoFactor"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { middleware: [auth] },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard"),
  },
  {
    path: "/notfound",
    name: "notFound",
    meta: { middleware: [auth] },
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFound"),
  },

  {
    path: "/users",
    name: "usersIndex",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "userI" */ "../views/Users/index"),
  },
  {
    path: "/userview/:id",
    name: "usersView",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "userV" */ "../views/Users/view"),
  },
  {
    path: "/useredit/:id",
    name: "usersEdit",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "userE" */ "../views/Users/edit"),
  },
  {
    path: "/usercreate",
    name: "usersCreate",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "userC" */ "../views/Users/create"),
  },
  {
    path: "/links",
    name: "linkIndex",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "linksI" */ "../views/Admin/Links/index"),
  },
  {
    path: "/linksedit",
    name: "linkEdit",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "linksE" */ "../views/Admin/Links/edit"),
  },
  {
    path: "/colors",
    name: "colorsIndex",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "colorI" */ "../views/Admin/Colors/index"),
  },
  {
    path: "/colorsedit",
    name: "colorsEdit",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "colorE" */ "../views/Admin/Colors/edit"),
  },

  {
    path: "/userpassword",
    name: "usersPassword",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "UserPassword" */ "../views/Users/password"),
  },
];
export default routes;
