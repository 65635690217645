import { getError } from "@/utils/helpers";
import NplanPhService from "@/services/nplan/NplanPhService";

export const namespaced = true;

export const state = {
  ph_planSingle: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_PHPLANSINGLE(state, ph_planSingle) {
    state.ph_planSingle = ph_planSingle;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getPHplan({ commit }, UserId) {
    commit("SET_LOADING", true);

    NplanPhService.getMain(UserId)
      .then((response) => {
        commit("SET_PHPLANSINGLE", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
};
export const getters = {
  ph_planSingle: (state) => {
    return state.ph_planSingle;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
