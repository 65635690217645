import { extend, configure } from "vee-validate";
import {
  required,
  email,
  min,
  max,
  required_if,
} from "vee-validate/dist/rules";
import { i18n } from "./i18n";

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`${field}`);

    return i18n.t(`validation.${values._rule_}`, values);
  },
});

// Install required rule and message.
extend("required", required);

// Install email rule and message.
extend("email", email);

// Install min rule and message.
extend("min", min);

extend("max", max);

extend("required_if", required_if);
