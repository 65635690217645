import auth from "@/middleware/auth";
import famchart from "@/middleware/famchart";
// eslint-disable-next-line no-unused-vars
import pcm from "@/middleware/pcm";
// eslint-disable-next-line no-unused-vars
import guest from "@/middleware/guest";

const lchartIndex = () =>
  import(/* webpackChunkName: "lchartI" */ "../views/Lchart/index");
const lchartCreate = () =>
  import(/* webpackChunkName: "lchartC" */ "../views/Lchart/create");
const lchartView = () =>
  import(/* webpackChunkName: "lchartV" */ "../views/Lchart/view");
const lchartEdit = () =>
  import(/* webpackChunkName: "lchartE" */ "../views/Lchart/edit");
const lchartMemberIndex = () =>
  import(
    /* webpackChunkName: "lchartmemberI" */ "../views/Lchart/member/index"
  );
const lchartMemberCreate = () =>
  import(
    /* webpackChunkName: "lchartmemberC" */ "../views/Lchart/member/create"
  );
const lchartMemberView = () =>
  import(/* webpackChunkName: "lchartmemberV" */ "../views/Lchart/member/view");
const lchartMemberEdit = () =>
  import(/* webpackChunkName: "lchartmemberE" */ "../views/Lchart/member/edit");
const lchartStatus = () =>
  import(
    /* webpackChunkName: "lchartstatus" */ "../views/Lchart/status/status"
  );
const lchartDef = () =>
  import(/* webpackChunkName: "lchartdef" */ "../views/Lchart/def/index");
const lchartAdef = () =>
  import(/* webpackChunkName: "lchartadef" */ "../views/Lchart/def/aDefLight");
const lchartBdef = () =>
  import(/* webpackChunkName: "lchartbdef" */ "../views/Lchart/def/bDefLight");
const lchartCdef = () =>
  import(/* webpackChunkName: "lchartcdef" */ "../views/Lchart/def/cDefLight");
const lchartDdef = () =>
  import(/* webpackChunkName: "lchartddef" */ "../views/Lchart/def/dDefLight");
const lchartEdef = () =>
  import(/* webpackChunkName: "lchartedef" */ "../views/Lchart/def/eDefLight");
const lchartFdef = () =>
  import(/* webpackChunkName: "lchartfdef" */ "../views/Lchart/def/fDefLight");

const lchartInvitation = () =>
  import(
    /* webpackChunkName: "lchartsinvitation" */ "../views/Lchart/status/invitation"
  );
const routes = [
  {
    path: "/lchart",
    name: "lchartIndex",
    meta: { middleware: [auth, famchart] },
    component: lchartIndex,
  },
  {
    path: "/lchartcreate",
    name: "lchartCreate",
    meta: { middleware: [auth, famchart] },
    component: lchartCreate,
  },
  {
    path: "/lchartview/:id",
    name: "lchartView",
    meta: { middleware: [auth, famchart] },
    component: lchartView,
  },
  {
    path: "/lchartedit/:id",
    name: "lchartEdit",
    meta: { middleware: [auth, famchart] },
    component: lchartEdit,
  },
  {
    path: "/lchartmember/:famId",
    name: "lchartMemberIndex",
    meta: { middleware: [auth, famchart] },
    component: lchartMemberIndex,
  },
  {
    path: "/lchartmembercreate/:famId",
    name: "lchartMemberCreate",
    meta: { middleware: [auth, famchart] },
    component: lchartMemberCreate,
  },
  {
    path: "/lchartmemberview/:famId/:id",
    name: "lchartMemberView",
    meta: { middleware: [auth, famchart] },
    component: lchartMemberView,
  },
  {
    path: "/lchartmemberedit/:famId/:id",
    name: "lchartMemberEdit",
    meta: { middleware: [auth, famchart] },
    component: lchartMemberEdit,
  },
  {
    path: "/lchartstatus/:famId",
    name: "lchartStatus",
    meta: { middleware: [auth, famchart] },
    component: lchartStatus,
  },
  {
    path: "/lchartdef/:famId",
    name: "lchartDef",
    meta: { middleware: [auth, famchart] },
    component: lchartDef,
  },
  {
    path: "/lchartadef/:famId",
    name: "lchartAdef",
    meta: { middleware: [auth, famchart] },
    component: lchartAdef,
  },
  {
    path: "/lchartbdef/:famId",
    name: "lchartBdef",
    meta: { middleware: [auth, famchart] },
    component: lchartBdef,
  },
  {
    path: "/lchartcdef/:famId",
    name: "lchartCdef",
    meta: { middleware: [auth, famchart] },
    component: lchartCdef,
  },
  {
    path: "/lchartddef/:famId",
    name: "lchartDdef",
    meta: { middleware: [auth, famchart] },
    component: lchartDdef,
  },
  {
    path: "/lchartedef/:famId",
    name: "lchartEdef",
    meta: { middleware: [auth, famchart] },
    component: lchartEdef,
  },
  {
    path: "/lchartfdef/:famId",
    name: "lchartFdef",
    meta: { middleware: [auth, famchart] },
    component: lchartFdef,
  },

  {
    path: "/lchartinivitation/:famId",
    name: "lchartInvitation",
    meta: { middleware: [auth, famchart] },
    component: lchartInvitation,
  },
];

export default routes;
