import router from "@/router";
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";

export const namespaced = true;

export const state = {
  user: null,
  loading: false,
  error: null,
  twofa: null,
};

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_2FA(state, twofa) {
    state.twofa = twofa;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  logout({ commit, dispatch }) {
    return AuthService.logout()
      .then(() => {
        commit("SET_USER", null);
        dispatch("setGuest", { value: "isGuest" });
        if (router.currentRoute.name !== "login")
          router.push({ name: "login" });
      })
      .catch((error) => {
        commit("SET_ERROR", getError(error));
      });
  },
  async getAuthUser({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await AuthService.getAuthUser();
      commit("SET_USER", response.data.data);
      commit("SET_LOADING", false);
      return response.data.data;
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_USER", null);
      commit("SET_ERROR", getError(error));
    }
  },
  async check2factor({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await AuthService.checktwofactor(payload);
      commit("SET_2FA", response.data.data.twofa);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_2FA", false);
      commit("SET_ERROR", getError(error));
    }
  },

  setGuest(context, { value }) {
    window.localStorage.setItem("guest", value);
  },
};

export const getters = {
  authUser: (state) => {
    return state.user;
  },
  isAdmin: (state) => {
    return state.user ? state.user.isAdmin : false;
  },
  isSuper: (state) => {
    return state.user ? state.user.isSuper : false;
  },
  hasExit: (state) => {
    return state.user ? state.user.hasExit : false;
  },
  hasFam: (state) => {
    return state.user ? state.user.hasFam : false;
  },
  hasPart: (state) => {
    return state.user ? state.user.hasPart : false;
  },
  hasPcm: (state) => {
    return state.user ? state.user.hasPcm : false;
  },
  twofa: (state) => {
    return state.twofa;
  },
  error: (state) => {
    return state.error;
  },
  loading: (state) => {
    return state.loading;
  },
  loggedIn: (state) => {
    return !!state.user;
  },
  guest: () => {
    const storageItem = window.localStorage.getItem("guest");
    if (!storageItem) return false;
    if (storageItem === "isGuest") return true;
    if (storageItem === "isNotGuest") return false;
  },
};
