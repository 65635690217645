import auth from "@/middleware/auth";

const npIndexBV = () =>
  import("@/views/noodplan/bv/index" /* webpackChunkName: "noodplanBVIndex" */);
const npCreateBV = () =>
  import(
    "@/views/noodplan/bv/create" /* webpackChunkName: "noodplanBVCreate" */
  );
const npViewBV = () =>
  import("@/views/noodplan/bv/view" /* webpackChunkName: "noodplanBVView" */);
const npEditBV = () =>
  import("@/views/noodplan/bv/edit" /* webpackChunkName: "noodplanBVEdit" */);
const npMailBV = () =>
  import("@/views/noodplan/bv/mail" /* webpackChunkName: "noodplanBVMail" */);
const npModuleBV = () =>
  import(
    "@/views/noodplan/bv/modules" /* webpackChunkName: "noodplanBVModules" */
  );
const npReportBV = () =>
  import(
    "@/views/noodplan/bv/reports" /* webpackChunkName: "noodplanBVReport" */
  );
const npModuleBV_A = () =>
  import(
    "@/views/noodplan/bv/module-a" /* webpackChunkName: "noodplanBVModule_A" */
  );
const npModuleBV_B = () =>
  import(
    "@/views/noodplan/bv/module-b" /* webpackChunkName: "noodplanBVModule_B" */
  );
const npModuleBV_C = () =>
  import(
    "@/views/noodplan/bv/module-c" /* webpackChunkName: "noodplanBVModule_C" */
  );
const npModuleBV_D = () =>
  import(
    "@/views/noodplan/bv/module-d" /* webpackChunkName: "noodplanBVModule_D" */
  );
const npModuleBV_E = () =>
  import(
    "@/views/noodplan/bv/module-e" /* webpackChunkName: "noodplanBVModule_E" */
  );
const npModuleBV_F = () =>
  import(
    "@/views/noodplan/bv/module-f" /* webpackChunkName: "noodplanBVModule_F" */
  );
const npModuleBV_G = () =>
  import(
    "@/views/noodplan/bv/module-g" /* webpackChunkName: "noodplanBVModule_G" */
  );
const npModuleBV_H = () =>
  import(
    "@/views/noodplan/bv/module-h" /* webpackChunkName: "noodplanBVModule_H" */
  );
const npModuleBV_I = () =>
  import(
    "@/views/noodplan/bv/module-i" /* webpackChunkName: "noodplanBVModule_I" */
  );
const npModuleBV_J = () =>
  import(
    "@/views/noodplan/bv/module-j" /* webpackChunkName: "noodplanBVModule_J" */
  );
const npModuleBV_K = () =>
  import(
    "@/views/noodplan/bv/module-k" /* webpackChunkName: "noodplanBVModule_K" */
  );
const npModuleBV_L = () =>
  import(
    "@/views/noodplan/bv/module-l" /* webpackChunkName: "noodplanBVModule_L" */
  );
const npModuleBV_M = () =>
  import(
    "@/views/noodplan/bv/module-m" /* webpackChunkName: "noodplanBVModule_M" */
  );
const npModuleBV_N = () =>
  import(
    "@/views/noodplan/bv/module-n" /* webpackChunkName: "noodplanBVModule_N" */
  );
const npIndexPH = () =>
  import("@/views/noodplan/ph/index" /* webpackChunkName: "noodplanPHIndex" */);

const routes = [
  {
    path: "/bv-index",
    component: npIndexBV,
    name: "bvIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-create",
    component: npCreateBV,
    name: "bvCreate",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-view/:id",
    component: npViewBV,
    name: "bvView",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-edit/:id",
    component: npEditBV,
    name: "bvEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-mail/:id",
    component: npMailBV,
    name: "bvMail",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-modules/:id",
    component: npModuleBV,
    name: "bvModules",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-a/:id",
    component: npModuleBV_A,
    name: "bvModule-A",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-b/:id",
    component: npModuleBV_B,
    name: "bvModule-B",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-c/:id",
    component: npModuleBV_C,
    name: "bvModule-C",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-d/:id",
    component: npModuleBV_D,
    name: "bvModule-D",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-e/:id",
    component: npModuleBV_E,
    name: "bvModule-E",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-f/:id",
    component: npModuleBV_F,
    name: "bvModule-F",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-g/:id",
    component: npModuleBV_G,
    name: "bvModule-G",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-h/:id",
    component: npModuleBV_H,
    name: "bvModule-H",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-i/:id",
    component: npModuleBV_I,
    name: "bvModule-I",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-j/:id",
    component: npModuleBV_J,
    name: "bvModule-J",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-k/:id",
    component: npModuleBV_K,
    name: "bvModule-K",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-l/:id",
    component: npModuleBV_L,
    name: "bvModule-L",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-m/:id",
    component: npModuleBV_M,
    name: "bvModule-M",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-module-n/:id",
    component: npModuleBV_N,
    name: "bvModule-N",
    meta: { middleware: [auth] },
  },
  {
    path: "/bv-report/:id",
    component: npReportBV,
    name: "bvReports",
    meta: { middleware: [auth] },
  },

  {
    path: "/ph-index",
    component: npIndexPH,
    name: "phIndex",
    meta: { middleware: [auth] },
  },
];
export default routes;
